<template>
	<section id="portfolio" class="list-activity">
		<b-row>
		    <b-col md="12">
		    	<h2 class="mb-2 pb-1">My Activities</h2>
		    </b-col>
		</b-row>
		<b-row>
		    <b-col md="12">  
				<template>
				  	<div class="size-16 w-20-percent-cl-1 w-20-percent-cl-2 w-17-percent-cl-3">
					    <b-table small :fields="fields" :items="items" responsive="sm" @row-clicked="myRowClickHandler" tbody-tr-class="cursor-pointer" show-empty>
					    	<template #head()="data">
					        	<span class="text-center d-block">{{ data.label }}</span>
					      	</template>
					      	<template #head(name)="data">
					        	<span class="text-left d-block">{{ data.label }}</span>
					      	</template>
					    	<template #head(project)="data">
					        	<span class="text-left d-block">{{ data.label }}</span>
					      	</template>
					      	<template #cell(status)="data">
					      		<span class="text-center d-block">
					      			<template v-if="data.item.status === '0'">Not Done</template>
						      		<template v-else-if="data.item.status === '1'">In Progress</template>
						      		<template v-else-if="data.item.status === '2'">Done</template>
						      		<template v-else-if="data.item.status === '3'">Terminated</template>   
					      		</span>					      		
					      	</template>
					      	<template #cell(commencement_date)="data">
					        	<span class="text-center d-block">{{ data.item.commencement_date | formatDatebyMoment("YYYY-MM-DD", "DD/MM/YY") }}</span>
					      	</template>
					      	<template #cell(completion_date)="data">
					        	<span class="text-center d-block">{{ data.item.completion_date | formatDatebyMoment("YYYY-MM-DD", "DD/MM/YY") }}</span>
					      	</template>
					      	<template #cell()="data">
					        	{{ data.value }}
					      	</template>
					      	<template #cell(name)="data">
					        	<span class="maxTwoRow">{{ data.item.name }}</span>
					      	</template>
					      	<template #cell(project)="data">
					        	<span class="maxTwoRow">{{ data.item.project }}</span>
					      	</template>
					      	<template #cell(venue)="data">
					      		<template v-if="data.item.venue">
					      			<span class="maxTwoRow text-center">{{ data.item.venue }}</span>
					      		</template>
					        	<template v-else><span class="text-center d-block">-</span></template>
					      	</template>
					      	<template #empty="scope">
						      	<h4 class="my-3 text-center" style="color: #CFCFCF !important; font-size: 14px !important">No data</h4>
						    </template>

					    </b-table>
				  	</div>
				</template>
		    </b-col>
		</b-row>
	</section>	
 
</template>

<script>

	export default {
	  	components: {},
	  	data() {
		    return {
			    fields: [			    	
			          { key: 'name', label: 'Activitiy Name' },
			          { key: 'project', label: 'Project Name' },
			          { key: 'venue', label: 'Venue' },
			          { key: 'status', label: 'Status' },
			          { key: 'commencement_date', label: 'Start Date' },
			          { key: 'completion_date', label: 'End Date' },
			        ],
				items: []
		    }
		},
		created(){
			this.fetchActivities();
		},
		methods: {
		  	myRowClickHandler(item) {
		    	this.$router.push(`/participant/my-activities/${item.id}`);
		  	},
		  	fetchActivities(){
				this.$store
				.dispatch('activities/fetchActivities', {})
				.then(response => {
					this.items = response.data.data
					console.log('list activities: ', response.data.data)
				})
				.catch((error) => {
					console.log(error)
				})
			}
		}
	};	

</script>

<style lang="scss">
	.black-color, .black-color-child *{
		color: #000000; 
	}
	h3{
		color: #8471FF;
	}
	.list-activity thead tr th *{
		color: #8471FF; 
		text-transform: capitalize;
	}
	.list-activity thead tr, .list-activity tbody tr{
		background: #fff; 
		box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
	}
	.list-activity thead tr th, .list-activity tbody tr td{
		padding: 16px 20px !important;
		font-size: 16px !important; 
		background: #fff !important
	}
	.list-activity table{
		border-collapse: separate;
    	border-spacing: 0 15px;
	}
	.cursor-pointer{
		cursor: pointer;
	}
</style>

